import React, { useMemo } from "react"
import { get } from "lodash"
// import { useWindowSizes } from '@hooks/window'
import { linkResolver } from "../../utils/linkResolver"
import { Strings } from "../../utils/strings"
import Image from "./Image"

const PdpInfo = ({ data, toggleSampleForm, qrCodeLayout }) => {
  console.log("[PdpInfo.js] data", data)

  // Main Info

  const lang = useMemo(() => get(data, "_meta.lang"), [data])

  const main_image = useMemo(() => get(data, "main_image"), [data])

  const certifications = useMemo(() => get(data, "certifications"), [data])

  const country_title = useMemo(
    () => get(data, "fazenda.country.title[0].text"),
    [data]
  )

  const country_flag = useMemo(() => get(data, "fazenda.country.flag"), [data])

  const fazenda_title = useMemo(() => get(data, "fazenda.title[0].text"), [
    data,
  ])

  const product_title = useMemo(() => get(data, "title[0].text"), [data])

  const product_notes = useMemo(() => get(data, "notes"), [data])

  const n_notes = product_notes?.length - 1

  const product_sku = useMemo(() => get(data, "sku[0].text"), [data])

  const harvest = useMemo(() => get(data, "harvest[0].text"), [data])

  const status = useMemo(() => get(data, "status[0].text", "-"), [data])

  const packaging = useMemo(() => get(data, "packaging[0].text", "-"), [data])

  const min_order = useMemo(() => get(data, "min_order[0].text", "-"), [data])

  const current_location = useMemo(
    () => get(data, "current_location[0].text", "-"),
    [data]
  )

  const bags_available = useMemo(() => get(data, "bags_available", "-"), [data])

  const harvest_year = useMemo(() => get(data, "harvest_year[0].text"), [data])

  const processing = useMemo(() => get(data, "processing[0].text"), [data])

  const variety = useMemo(() => get(data, "variety[0].text"), [data])

  const altitude = useMemo(() => get(data, "altitude[0].text"), [data])

  const details = useMemo(() => get(data, "details"), [data])

  // console.log('main_details', main_details)

  // Graph Info

  const family_name = useMemo(() => get(data, "family.title[0].text"), [data])

  const family_image = useMemo(
    () => get(data, "family.illustration_individual"),
    [data]
  )

  const family_images = useMemo(() => get(data, "family.illustration_couple"), [
    data,
  ])

  const body_strength = useMemo(() => get(data, "body_strength"), [data])
  const bitter_strength = useMemo(() => get(data, "bitter_strength"), [data])
  const aroma_strength = useMemo(() => get(data, "aroma_strength"), [data])
  const acidity_strength = useMemo(() => get(data, "acidity_strength"), [data])

  const graph_acidity_label = useMemo(() => get(data, "graph_acidity_label"), [
    data,
  ])
  const graph_aroma_label = useMemo(() => get(data, "graph_aroma_label"), [
    data,
  ])
  const graph_bitter_label = useMemo(() => get(data, "graph_bitter_label"), [
    data,
  ])
  const graph_body_label = useMemo(() => get(data, "graph_body_label"), [data])

  const family_specs = useMemo(() => get(data, "specs"), [data])

  const contactDoc = { uid: "contact-us", lang: lang }
  const contactUrl = linkResolver(contactDoc)

  const qrCodeClass = qrCodeLayout ? " pdp_info-qr_code" : ""

  const allStrings = Strings(lang)

  // Sample Forms
  const body1 = get(data, "body1")
  const body1Size = body1?.length

  // console.log('family_specs', family_specs)

  // Functions

  // const renderMainDetail = (detail, index) => {
  //   const detail_name = get(detail, 'name[0].text')
  //   const detail_value = get(detail, 'detail[0].text')

  //   return (
  //     <div className="pdp_info__details__specs__item" key={index}>
  //       <label>{detail_name}</label>
  //       <p className="p-medium font-book">{detail_value}</p>
  //     </div>
  //   )
  // }

  const renderGraphLine = strength => {
    let lines = []
    for (let i = 0; i <= 6; i++) {
      if (i >= strength) {
        lines.push(
          <div key={i} className="pdp_info__family__graph__line small"></div>
        )
      } else {
        lines.push(
          <div key={i} className="pdp_info__family__graph__line large"></div>
        )
      }
    }
    return lines
  }

  const renderFamilySpec = (spec, index) => {
    const spec_name = get(spec, "name[0].text")
    const spec_value = get(spec, "description1[0].text")

    return (
      <div className="pdp_info__family__specs__item" key={index}>
        <label>{spec_name}</label>
        <p className="p-medium font-book">{spec_value}</p>
      </div>
    )
  }

  const renderDetail = (item, index) => {
    // console.log("renderDetail item", item)

    const title = get(item, "title1")
    const text = get(item, "text")

    return (
      <div className="pdp_info__details__specs__item" key={index}>
        <label>{title}</label>
        <p className="p-medium font-book">{text}</p>
      </div>
    )
  }

  return (
    <section className={"pdp_info" + qrCodeClass}>
      <div className="row">
        {!qrCodeLayout ? (
          <div className="col col5 pdp_info__left_content">
            <div className="pdp_info__main_image">
              {certifications && certifications.length > 0 ? (
                <div className="pdp_info__main_image__certificates">
                  {certifications?.map((certif, index) => {
                    const certif_image = get(certif, "certification")
                    return (
                      <img
                        key={index}
                        src={certif_image?.url}
                        alt={certif_image?.alt}
                      />
                    )
                  })}
                </div>
              ) : null}

              {/* <img 
                className="pdp_info__main_image__img"
                src={main_image?.url} 
                alt={main_image?.alt ?? product_title} /> */}
              <div className="pdp_info__main_image__img">
                <Image image={main_image} width={560} height={700} />
              </div>
            </div>
          </div>
        ) : null}

        <div className="col col7 pdp_info__right_content">
          <div className="pdp_info__details">
            <div className="pdp_info__details__origin">
              <div className="pdp_info__details__origin__left">
                <img
                  className="pdp_info__details__origin__family_img"
                  src={family_image?.url}
                  alt={family_image?.alt ?? family_name}
                />

                <div className="pdp_info__details__origin__family_info">
                  <div className="country_tag">
                    <img
                      src={country_flag?.url}
                      alt={country_flag?.alt ?? country_title}
                    />
                    {country_title}
                  </div>
                  <h5 className="p-large font-book">{fazenda_title}</h5>
                </div>
              </div>

              {harvest ? (
                <div className="pdp_info__details__origin__right">
                  <div className="pdp_info__details__origin__label">
                    <div className="tag">{harvest}</div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="pdp_info__details__main_info">
              <h1 className="pdp_info__details__main_info__title h2">
                {product_title}
              </h1>
              <div className="pdp_info__details__main_info__notes p-small">
                {product_notes?.map((note, index) => {
                  const note_value = get(note, "note[0].text")

                  if (n_notes === index) {
                    return <span key={index}>{note_value}</span>
                  } else {
                    return <span key={index}>{note_value}&nbsp;|&nbsp;</span>
                  }
                })}
              </div>
              <div className="pdp_info__details__main_info__sku p-small">
                {product_sku}
              </div>
            </div>

            <div className="pdp_info__details__main__btns">
              <a
                href={contactUrl}
                className="btn-secondary black small w-arrow"
              >
                {allStrings?.main_contact_us ?? "Contact Us"}
              </a>

              {/* <a 
                className="btn-secondary white small w-arrow" 
                onClick={() => {toggleSampleForm()} }>
                {allStrings?.cards_product_order_sample ?? 'Order a Sample'}
              </a> */}

              {body1Size > 0 && (
                <button
                  className="btn-secondary white small w-arrow"
                  onClick={() => {
                    toggleSampleForm()
                  }}
                >
                  {allStrings?.cards_product_order_sample ?? "Order a Sample"}
                </button>
              )}
            </div>

            {/* <div className="pdp_info__details__specs">
              <div className="pdp_info__details__specs__item">
                <label>{allStrings?.coffee_variety ?? "Variety"}</label>
                <p className="p-medium font-book">{variety}</p>
              </div>

              <div className="pdp_info__details__specs__item">
                <label>{allStrings?.coffee_process ?? "Process"}</label>
                <p className="p-medium font-book">{processing}</p>
              </div>

              <div className="pdp_info__details__specs__item">
                <label>{allStrings?.coffee_altitude ?? "Altitude"}</label>
                <p className="p-medium font-book">{altitude}</p>
              </div>

              <div className="pdp_info__details__specs__item">
                <label>
                  {allStrings?.coffee_harvest_year ?? "Harvest Year"}
                </label>
                <p className="p-medium font-book">{harvest_year}</p>
              </div>
            </div> */}

            {/* Second specs */}
            <div className="pdp_info__details__specs">
              {details?.map((item, index) => renderDetail(item, index))}

              {/* <div className="pdp_info__details__specs__item">
                <label>
                  {allStrings?.coffee_minimum_order ?? "Min. Order"}
                </label>
                <p className="p-medium font-book">
                  {min_order !== "" ? min_order : 0}
                </p>
              </div>
              <div className="pdp_info__details__specs__item">
                <label>{allStrings?.coffee_packaging ?? "Packaging"}</label>
                <p className="p-medium font-book">{packaging}</p>
              </div>
              <div className="pdp_info__details__specs__item">
                <label>
                  {allStrings?.coffee_bags_available ?? "Bags Available"}
                </label>
                <p className="p-medium font-book">
                  {bags_available !== "" && bags_available !== null
                    ? bags_available
                    : "-"}
                </p>
              </div>
              <div className="pdp_info__details__specs__item">
                <label>
                  {allStrings?.coffee_current_location ?? "Current Location"}
                </label>
                <p className="p-medium font-book">{current_location}</p>
              </div>
              <div className="pdp_info__details__specs__item">
                <label>{allStrings?.coffee_status ?? "Status"}</label>
                <p className="p-medium font-book">{status}</p>
              </div> */}
            </div>
          </div>

          <div className="pdp_info__family">
            <div className="pdp_info__family__header">
              <h2 className="pdp_info__family__header__title special-font">
                {family_name}
              </h2>
              <img
                className="pdp_info__family__header__img"
                src={family_images?.url}
                alt={family_images?.alt ?? family_name}
              />
            </div>

            <div className="pdp_info__family__graph">
              <div className="pdp_info__family__graph__section">
                <label>
                  {graph_body_label
                    ? graph_body_label
                    : allStrings?.family_graph_body ?? "Body"}
                </label>
                <div className="pdp_info__family__graph__section__inner">
                  {renderGraphLine(body_strength)}
                </div>
              </div>

              <div className="pdp_info__family__graph__section">
                <label>
                  {graph_bitter_label
                    ? graph_bitter_label
                    : allStrings?.family_graph_bitter ?? "Bitter"}
                </label>
                <div className="pdp_info__family__graph__section__inner">
                  {renderGraphLine(bitter_strength)}
                </div>
              </div>

              <div className="pdp_info__family__graph__section">
                <label>
                  {graph_aroma_label
                    ? graph_aroma_label
                    : allStrings?.family_graph_aroma ?? "Aroma"}
                </label>
                <div className="pdp_info__family__graph__section__inner">
                  {renderGraphLine(aroma_strength)}
                </div>
              </div>

              <div className="pdp_info__family__graph__section">
                <label>
                  {graph_acidity_label
                    ? graph_acidity_label
                    : allStrings?.family_graph_acidity ?? "Acidity"}
                </label>
                <div className="pdp_info__family__graph__section__inner">
                  {renderGraphLine(acidity_strength)}
                </div>
              </div>
            </div>
            <div className="pdp_info__family__specs">
              {family_specs?.map((spec, index) =>
                renderFamilySpec(spec, index)
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PdpInfo
