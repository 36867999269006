import React, { useMemo, useState } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import PdpNavigation from '../components/slices/PdpNavigation'
import PdpInfo from "../components/slices/PdpInfo"
// import PdpShipping from '../components/slices/PdpShipping'
import FarmLayout from "../components/FarmLayout"
import PdpRequestSample from "../components/slices/PdpRequestSample"
import { linkResolver } from "../utils/linkResolver"
import { Strings } from "../utils/strings"

const PdpCoffee = ({ page, fazenda }) => {
  // console.log('[PdpCoffee.js] page', page)

  const lang = useMemo(() => get(page, "_meta.lang"), [page])

  const allStrings = Strings(lang)
  const contactDoc = { uid: "contact-us", lang: lang }
  const contactUrl = linkResolver(contactDoc)

  // Toggle Sample Form State

  const [sampleFormState, setSampleFormState] = useState("")
  const toggleSampleForm = () => {
    // setSortButtonState(!sortButtonState)
    if (sampleFormState === "") {
      setSampleFormState(" open")
    } else {
      setSampleFormState("")
    }
  }

  const renderContainer = (container, index) => {
    if (container && container.type === "fazenda") {
      return (
        <FarmLayout
          key={index}
          data={fazenda}
          index={index}
          isFarmPage={false}
          qrCodeLayout={true}
        />
      )
    }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        <PdpInfo
          data={page}
          toggleSampleForm={toggleSampleForm}
          qrCodeLayout={true}
        />
        <PdpRequestSample
          data={page}
          state={sampleFormState}
          toggleSampleForm={toggleSampleForm}
        />

        {page &&
          page?.body?.map((container, index) =>
            renderContainer(container, index)
          )}

        <div className="qr_code__buttons">
          <a href={contactUrl} className="btn-secondary black small w-arrow">
            {allStrings?.main_contact_us ?? "Contact Us"}
          </a>

          <button
            className="btn-secondary white small w-arrow"
            onClick={() => {
              toggleSampleForm()
            }}
          >
            {allStrings?.cards_product_order_sample ?? "Order a Sample"}
          </button>
        </div>
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allProducts.edges[0]
  const docFazenda = props.data.prismic.allFazendas.edges[0]

  if (!doc) return null

  // const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(() => get(doc, "node.seo_image.url"), [doc])

  return (
    <Layout pathname={props.path} noFooter={true}>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <PdpCoffee page={doc.node} fazenda={docFazenda?.node} />
    </Layout>
  )
}

export const query = graphql`
  query pdpCoffeeQr($lang: String, $uid: String, $fazenda_uid: String) {
    prismic {
      allProducts(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            seo_title
            seo_meta
            seo_image
            family {
              ... on PRISMIC_Coffee_family {
                _meta {
                  uid
                }
                title
                description
                illustration_individual
                illustration_outline_couple
                illustration_couple
              }
            }
            title
            sku
            notes {
              note
            }
            # harvest
            # current_location
            # bags_available
            # harvest_year
            # min_order
            # packaging
            # processing
            # status
            # variety
            # altitude
            main_image
            acidity_strength
            aroma_strength
            bitter_strength
            body_strength
            details {
              title1
              text
              show_in_mini_card
            }
            specs {
              name
              description1
            }
            certifications {
              certification
            }
            fazenda {
              ... on PRISMIC_Fazenda {
                title
                _linkType
                country {
                  ... on PRISMIC_Country {
                    title
                    flag
                  }
                }
              }
            }
            body {
              ... on PRISMIC_ProductBodyText___illustration {
                type
                label
                primary {
                  title1
                  text
                  illustration
                }
              }
              ... on PRISMIC_ProductBodyFazenda {
                type
                label
              }
            }
          }
        }
      }
      allFazendas(lang: $lang, uid: $fazenda_uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
            }
            body {
              ... on PRISMIC_FazendaBodyText___image {
                type
                label
                primary {
                  image
                  image_aligment
                  text
                }
              }
              ... on PRISMIC_FazendaBodyImages_editorial {
                type
                label
                primary {
                  image_1
                  image_2
                  image_3
                  layout
                }
              }
              ... on PRISMIC_FazendaBodyRelated_products {
                type
                label
                primary {
                  title1
                  products
                }
                fields {
                  related_product {
                    ... on PRISMIC_Product {
                      _meta {
                        uid
                        lang
                        type
                      }
                      family {
                        ... on PRISMIC_Coffee_family {
                          title
                          description
                          illustration_individual
                        }
                      }
                      title
                      sku
                      notes {
                        note
                      }
                      # harvest
                      # current_location
                      # bags_available
                      main_image
                      fazenda {
                        ... on PRISMIC_Fazenda {
                          title
                          _linkType
                          _meta {
                            uid
                          }
                          country {
                            ... on PRISMIC_Country {
                              title
                              flag
                            }
                          }
                        }
                      }
                      # harvest_year
                      # min_order
                      # packaging
                      # processing
                      # status
                      # variety
                    }
                  }
                }
              }
            }
            characteristics {
              title1
              text
            }
            country {
              ... on PRISMIC_Country {
                title
                flag
              }
            }
            title
            show_related_products
            main_image
            local_location
            intro
          }
        }
      }
    }
  }
`
